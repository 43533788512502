<template>
  <div
    v-shortkey="['esc']"
    class="modal-wrapper"
    @contextmenu.self.prevent
    @click="closeModal"
    @shortkey="closeModal"
  >
    <div class="modal-wrapper-bg">
      <div
        class="modal-wrapper__content modal-wrapper__templates"
        @click.stop
        @contextmenu.prevent
      >
        <div class="modal-wrapper__close-container">
          <div
            class="modal-wrapper__close"
            @click="closeModal"
          >
            <img
              src="../../assets/images/close-icon.svg"
              alt="close"
            >
          </div>
        </div>
        <templates-content
          :ifLoader="ifLoader"
        ></templates-content>
      </div>
    </div>
  </div>
</template>

<script>
import templatesContent from '../templatesContent';

export default {
  name: 'ModalsTemplates',
  components: {
    templatesContent,
  },
  data() {
    return {
      ifLoader: false,
      validator: {},
      verifyCaptchaPropDef: false,
      templates: [],
      loadingStart: 800,
    };
  },
  computed: {
    showModalTemplates: {
      get() {
        return this.$store.getters.showModalTemplates;
      },
      set(data) {
        this.$store.commit('setShowModalTemplates', data);
      },
    },
  },
  methods: {
    closeModal() {
      this.$store.state.showModalTemplates = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/sass/utils/variables';

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #00CBF8;
  border-radius: 9px;
}

.modal-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 9999;
  opacity: 1;
  height: 100%;
  padding: 60px;
  &-bg {
    margin: auto;
  }
  &__content {
    background: #F6F9FA;
    position: relative;
    padding: 0 15px 110px 15px;
  }
  &__templates {
    overflow: auto;
    height: calc(100vh - 120px);
    min-width: calc(100vw - 120px);
    border-radius: 10px;
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 32px;
    font-size: 16px;
    color: $color-jumbo;
    transition: transform .15s ease-in;
    cursor: pointer;

    &:hover {
      transform: rotate(90deg);
    }
  }

  &__close-container {
    position: sticky;
    height: 70px;
    background-color: #F6F9FA;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
  }

  &__title {
    font-family: $font-global-bold;
    line-height: 40px;
    font-size: 31px;
    color: $color-shark;
    text-align: center;
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px)  {
  .modal-wrapper {
    padding: 25px;

    ::-webkit-scrollbar {
      display: none;
    }

    &__content {
      padding: 0 15px 60px 15px;
    }

    &__close {
      top: 10px;
      right: 10px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (max-width: 480px)  {
  .modal-wrapper {
    padding: 0;

    &__templates {
      border-radius: 0;
      height: 100vh
    }

    &__content {
      padding: 0 15px 40px 15px;
    }

    &__title {
      font-size: 19px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    &__close-container {
      height: 60px;
    }
  }
}

.loading-spiral {
  border: solid 3px $color-bright-turquoise !important;
  border-right-color: transparent !important;
}
</style>
