<template>
  <div class="content content_full">
    <h2 class="featured-template__header">
      Pick The Website Template or Order Custom Design
    </h2>
    <div class="featured-template__wrapper">
      <div class="websites-list">
        <div
          v-for="(item, indexSite) in templates"
          :key="indexSite"
          class="featured-template-item__wrapper"
        >
          <div v-if="!indexSite">
            <h2 class="featured-template__title">
              All Website Templates
            </h2>
          </div>
          <div
            class="featured-template-item"
          >
            <div class="websites-item__img">
              <template v-if="item.image !== undefined">
                <img
                  :src="item.contentStructure.siteStructure.imageTemplate"
                  alt="image"
                />
              </template>
            </div>
            <div
              class="featured-template-hidden"
            >
              <div
                class="featured-template-hidden-content"
              >
                <div
                  href=""
                  class="featured-template-hidden__edit btn btn-second prolend-primary"
                  @click.prevent="editTemplate()"
                >
                  Edit
                </div>
                <a
                  href=""
                  class="featured-template-hidden__preview btn prolend-primary"
                  @click.prevent="previewSiteLink(item)"
                >
                  View
                </a>
              </div>
            </div>
          </div>
          <div class="websites-item-footer__name">
            Template #{{ indexSite + 1 }}
          </div>
        </div>
      </div>
    </div>
    <infiniteLoading
      ref="loaderInf"
      spinner="spiral"
      @infinite="infiniteHandler"
    >
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infiniteLoading>
  </div>
</template>

<script>
import templateApi from '@/api/getTemplates';
import infiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    infiniteLoading,
  },
  props: ['ifLoader'],
  data() {
    return {
      validator: {},
      verifyCaptchaPropDef: false,
      loadingStart: 800,
    };
  },
  computed: {
    templates: {
      get() {
        console.log(this.$store.getters.templates);
        return this.$store.getters.templates;
      },
      set(data) {
        this.$store.commit('setTemplates', data);
      },
    },
    templatesTotal: {
      get() {
        return this.$store.getters.templatesTotal;
      },
      set(data) {
        this.$store.commit('setTemplatesTotal', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  methods: {
    previewSiteLink(item) {
      const a = document.createElement('a');
      const name = item.title.includes('Newsfeed') ? 'ii' : '';
      a.target = '_blank';
      a.href = `https://gracechurch${name}.impactfactors.net/widget/website/${item.previewLink}`;
      a.click();
    },
    editTemplate() {
      this.showModalGetStarted = true;
      this.$store.state.showModalTemplates = false;
    },
    infiniteHandler($state) {
      const page = this.templates.length;
      if (page === this.templatesTotal) return;
      templateApi.getSiteAsTemplate(page)
        .then(({ data }) => {
          const { items, totalCount } = data;
          this.templatesTotal = totalCount;
          this.templates.push(...items);
          if (items.length < 10) {
            $state.complete();
          } else {
            $state.loaded();
          }
        })
        .catch((err) => {
          $state.complete();
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/utils/variables';

.featured-template {
  &__title {
    font-family: $font-global-bold;
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    color: #333636;
    top: -40px;
    left: 18px;
    margin-top: 7px;
    position: absolute;
  }

  &__header {
    max-width: 630px;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-family: $font-global-black;
    font-size: 48px;
    line-height: 60px;
  }

  &-hidden {
    position: absolute;
    width: 100%;
    background: rgba(0,0,0,.7);
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all .15s ease-in;
    pointer-events: none;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }
  }

  &-item {
    max-width: 393px;
    min-width: 393px;
    margin: 12px 16px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 264px;
    overflow: hidden;
    transition: box-shadow .2s ease-in;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid $color-mercury;
    position: relative;

    &:hover {
      box-shadow: 0 2px 6px rgba(18, 22, 37, 0.08);

      .featured-template-hidden {
        opacity: 1;
        pointer-events: all;
        background: rgba(255, 255, 255, 0.95);

        &__preview, &__edit {
          min-width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 11px;
          border-radius: 24px;
          font-family: $font-global;
          border: 1px !important;
          font-size: 14px;
          font-weight: 600;
          transition: .3s all;
          color: white;
          &:hover {
            color: $color-turquoise;
            border: 1px !important;
            //border: none;
          }
        }

        &__edit {
          background-color: $color-turquoise;
          margin-bottom: 10px;
        }

        &__preview {
          border: 1px solid $color-turquoise;
          color: $color-turquoise;
          background-color: rgba(255, 255, 255, 0.5);
          &:hover {
            color: white;
            border: 1px solid $color-turquoise;
          }
        }
      }
    }

    &__img {
      border-radius: 4px;
      font-size: 0;
      line-height: 0;
      min-height: 274px;
      height: 100%;
      top: 0 ;
      position: relative;
      left: 0;
      transition: top .5s linear;
      max-width: 100%;

      &_active {
        transition: top 3s linear;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      border-top: 1px solid $color-concrete;
      font-family: $font-global, sans-serif;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 45px;
      padding: 0 16px;
      width: 100%;
    }

    &__wrapper {
      position: relative;
      margin-top: 55px;
    }
  }
}

.websites {
  &-hidden {
    position: absolute;
    width: 100%;
    background: rgba(0,0,0,.7);
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    opacity: 0;
    transition: all .15s ease-in;
    pointer-events: none;

    &-content {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    flex-wrap: wrap;
  }

  &-item {
    max-width: 280px;
    width: 100%;
    min-width: 280px;
    border: 1px solid $color-concrete;
    border-radius: 4px;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: box-shadow .2s ease-in;

    &:hover {
      box-shadow: 1px 10px 15px 0 rgba(0, 0, 0, .2);

      .websites-hidden {
        opacity: 1;
        pointer-events: all;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &__img {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      min-height: 264px;
      overflow: hidden;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        transition: .3s all;
      }
    }

    &-footer {
      border-top: 1px solid $color-concrete;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 32px;
      padding: 0 8px;
      position: relative;

      &__left {
        display: flex;
        align-items: center;
      }

      &__publish {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $color-ocean-green;
        position: relative;
        left: 8px;
      }

      &__name {
        font-family: $font-global, sans-serif;
        font-size: 16px;
        margin-left: 9px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 1px;
        color: $color-shark2;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    min-height: 65px;
    justify-content: space-between;
    background: $color-seashell;
    padding: 0 15px;
    margin-bottom: 22px;

    &__new-proj {
      width: 162px;
      margin-left: 24px;
      font-family: $font-global, sans-serif;
      font-size: 14px;

      &-content {
        padding-left: 8px;
        position: relative;
        top: 2px;
      }
    }

    &__search {
      width: 252px;

      .search__input {
        padding: 0 10px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }
}

@media (max-width: 1000px) {
  .featured-template {
    &__header {
      margin-top: 0;
      font-size: 44px;
      line-height: 54px;
    }
  }
}

@media (max-width: 708px)  {
  .featured-template {
    &-item {
      min-width: auto;
      min-height: auto;
      margin: 10px 5px;

      &__wrapper {
        margin-top: 25px;
        &:first-child {
          margin-top: 55px;
        }
      }
    }
    &__header {
      font-size: 30px;
      line-height: 34px;
    }
  }
}

.btn-second {
  &:before {
    border: 1px solid $color-turquoise;
  }
}
</style>
